import React, { ReactElement, ReactNode, createContext, useState } from "react";
import Head from "next/head";
import Header from "@components/shared/header";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "@redux/shared/actions";
import { RootState } from "@redux/reducers";
import dynamic from "next/dynamic";
import { useDomainContext } from "@hooks/useDomainContext";
import EnduranceWarrantyProtectLogo from "@components/endurancewarrantyprotect/logo";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";
import EnduranceWarrantyProtectFooter from "@components/endurancewarrantyprotect/footer/footer";
import CompareWhatCoveredModal from "@components/endurancewarrantyprotect/compareWhatCoveredModal";
const Modal = dynamic(() => import("@components/shared/modal"));

const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));

const TermsAndConditions = dynamic(
    () => import("@components/shared/legal/termsAndConditions"),
);

const PrivacyPolicy = dynamic(
    () => import("@components/shared/legal/privacyPolicy"),
);

export const PlanContext = createContext<{
    activePlan: string;
    setActivePlan: (plan: string) => void;
}>({
    activePlan: "",
    setActivePlan: () => {},
});

export default function EnduranceWarrantyProtectWrapper({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const [activePlan, setActivePlan] = useState("");
    const dispatch = useDispatch();
    const { pageType } = useDomainContext();
    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };
    const { active: modalActive, content: modalContent } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );

    const getModalContent = () => {
        switch (modalContent) {
            case "privacy":
                return <PrivacyPolicy />;
            case "terms":
                return <TermsAndConditions />;
            case "sideWidget":
                return <Mobile />;
            case "compareCovered":
                return <CompareWhatCoveredModal />;
            default:
                return null;
        }
    };

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;800&display=swap"
                    rel="stylesheet"
                />
            </Head>

            <div style={{ fontFamily: `'Poppins', sans-serif` }}>
                <PlanContext.Provider
                    value={{
                        activePlan,
                        setActivePlan,
                    }}
                >
                    <Header
                        phoneNumberColor="#000"
                        iconColor="#000"
                        textColor="#000"
                        hideSticky={pageType === "thankyou"}
                    >
                        <EnduranceWarrantyProtectLogo />
                    </Header>
                    <main className="block">{children}</main>
                    <EnduranceWarrantyProtectFooter />
                    <Modal
                        onClose={handleClose}
                        active={modalActive}
                        closeIcon={<CloseIcon />}
                        backdropClosable
                        modalStyles={{
                            display: "flex",
                            flexDirection: "column",
                            maxHeight:
                                modalContent === "sideWidget" ? "80vh" : "100%",
                        }}
                        modalClassName="h-[90vh] rounded"
                        bodyStyles={{ overflowY: "scroll" }}
                        width={900}
                        header={
                            modalContent === "privacy"
                                ? "Privacy Policy"
                                : modalContent === "terms"
                                ? "Terms and Conditions"
                                : modalContent === "compareCovered"
                                ? undefined
                                : modalContent === "sideWidget"
                                ? "Contents"
                                : undefined
                        }
                    >
                        {getModalContent()}
                    </Modal>
                </PlanContext.Provider>
            </div>
        </>
    );
}
